<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydHaiNanHXB/header.png"
    />
    <div class="content">
      <van-form>
        <img
          class="step1"
          v-show="num == 1"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
        />
        <van-field
          v-model="form.phone"
          type="tel"
          maxlength="11"
          placeholder="请输入您的手机号码"
        />
        <img
          class="step2"
          v-if="num == 2"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif"
        />
        <img
          class="step3"
          v-if="num == 3"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif"
        />
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入6位短信验证码"
          class="input-code"
        >
          <template slot="right-icon">
            <div class="code" v-show="sendAuthCode" @click="getAuthCode">
              获取验证码
            </div>
            <div class="code" v-show="!sendAuthCode" style="font-size: 15px">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydHaiNanHXB/button.gif"
          class="submit"
          @click="onSubmit"
        />

        <div class="checked">
          <div>客服电话：4001189599</div>
          <div>客服工作时间：周一至周日：9:00-17:30</div>

          <van-checkbox
            checked-color="#ffffff"
            v-model="checked"
            icon-size="18px"
          >
            &nbsp; 我已阅读并同意<span
              class="book"
              @click="dialogInfo('个人信息授权与保护声明')"
              >《个人信息授权与保护声明》</span
            >
          </van-checkbox>
        </div>
      </van-form>
      <!-- <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/HaiNanYD/product.png" /> -->

      <div style="display: flex; justify-content: center; align-items: center">
        <img
          style="width: 148px"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydHaiNanHXB/rule.png"
        />
      </div>

      <div class="info" style="margin-top: 20px">
        <!-- <div class="text" style="font-weight: 700;"> 一、活动详情</div>
        <div class="text">
          1、活动资费：原价39.9元/月，约定使用12个月，
          <span style="color: #fff08f;">约定期间及合约期结束后均享受优惠价26.9元/月。</span>
        </div> -->
        <div class="text">
          1、
          <span style="color: #fff08f; font-weight: 700"
            >「5G惠享包」首月5元，次月起15元/月，含10GB/月国内通用流量(不含港澳台)，前12个月每月加赠2GB国内流量，</span
          >
          12个月后活动优惠价格不变，不再额外赠送流量，约定在网1年，合约到期当月起可取消，订购立即生效。
        </div>
        <div class="text">2、主套餐38元及以上客户可办理此活动。</div>
        <div class="text">
          3、
          <span style="color: #fff08f; font-weight: 700"
            >活动合约期结束次月起不再额外加赠流量</span
          >
          ，15元10GB惠享流量包优惠继续保留，价格不变(15元月)，如不需要可随时退订。
        </div>
        <div class="text">
          4、活动所含的流量不可结转、不可赠送或多终端共享，10GB流量为主套餐前流量，每月附赠的2GB在主套餐流量使用完后即可生效使用(在限速套餐之前使用)。
        </div>
        <div class="text">
          5、活动互斥条件:与5G尝鲜包升级活动互斥;60天内仅能参与一次。
        </div>
        <div class="text">
          6、约定在网及违约条款:如原有约定在网活动未结束，则本活动约定在网时间往后顺延。
          <span style="color: #fff08f; font-weight: 700"
            >约定在网期间，不可办理携号转网。约定在网期间如需提前终止在网合约，需缴清违约金后进行特殊终止，违约金收取公式:2*加赠月数*2元。</span
          >
        </div>
        <div class="text">
          7、活动内含流量为2/3/4/5G国内通用流量(不含港澳台)，5G网速需具备5G网络及5G终端，并满足归属地运营商规定的其他使用条件
        </div>
        <div class="text">
          8、以上内容不构成要约，活动退订方式及失效时间等更多详情，可拨打10086热线或到当地营业厅咨询。
        </div>
        <br />
      </div>
    </div>
    <img
      class="img"
      style="width: 100%; height: 100%"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydHaiNanHXB/mobile.png"
    />
    <BaseDialogConfirm
      :width="682"
      :height="727"
      background="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GEnjoymentPackage/dialog.png"
      :visibility.sync="visibility"
      closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/cancel.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/YD/HaiNan/5GPremiumPackage/confirm.png"
    />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import BaseDialogConfirm from "@/components/BaseDialog/confirm.vue";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common";

export default {
  mixins: [tailJumpMixin],
  components: {
    BaseDialogConfirm,
  },
  data() {
    return {
      form: {
        productNo: "69901084",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      visibility: false,
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
    };
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, that.form.phone, "退出")
      );
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/YDHaiNan/foodCouponMember`;
    },
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  methods: {
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.yzm_loading = true;

      getVerificationCode(
        JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
      )
        .then((res) => {
          if (res.code != 0) {
            this.$dialog.alert({ message: res.message || "验证码获取失败" });
            setTimeout(() => {
              window.location.replace(
                this.tailJumpUrl(
                  this.link,
                  `QIUMORONGHE`,
                  this.form.phone,
                  "验证码下发失败"
                )
              );
            }, 3000);
          } else {
            this.form.data = res.data.data.data;
            this.form.orderId = res.data.data.orderId;
            // 先设置倒计时秒
            this.sendAuthCode = false;
            this.auth_time = 120;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
          }
        })
        .finally(() => {
          // 结束加载状态
          this.yzm_loading = false;
        });
    },

    // 点击确认订购
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.close();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          JuDuoDuo("100023", this.form.phone, "海南移动-5G惠享包");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" })
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请勾选协议",
        });
      } else {
        this.visibility = true;
      }
    },
    dialogInfo(title) {
      const text1 = `
一、业务介绍
1、沃畅游99至尊会员 ，25元/月。订购成功立即生效，不退订连续包月，退订成功后月底失效。
2、沃畅游99至尊会员为游戏玩家提供专享服务：
①沃畅游客户端/盒子端（8或15小时或5小时，根据具体活动页面规则）小时主机时长，手游不限时畅玩；
②热门权益每月任选，当月可领，次月可更换不同权益：
<span style="color: red;">注：热门特权礼包内容不定期更新调整，具体权益内容以权益兑换页面内容为准；特权礼包需每月自行领取，逾期不可补领上月权益；</span>

二、适用范围
（一）适用对象：
仅限联通手机用户，包括4G、5G、及3G/4G、4G/5G融合套餐用户；
（二）办理范围：
全国用户。

三、使用规则
（一）沃畅游时长：【目前仅支持安卓用户，IOS用户请关注其他活动，为您带来的不便敬请理；】无需领取，订购成功自动下发，续订期间每月5号前自动下发；使用订购手机号，登录联通畅游客户端即可查看、使用；
（二）特权礼包N选1或2：
①影音视听类：芒果TV会员 (PC移动影视)月卡、酷我音乐月卡、芒果TV会员(PC移动影视)周卡、搜狐视频月卡、哗哩哗哩月卡、优酷视频会员周卡、爱奇艺视频月卡、优酷视频月卡、腾讯视频月卡；
②生活类：QO普通会员月卡、QQ黄钻会员月卡、百度文库会员月卡。
注：热门权益会有所调整，具体权益内容以权益兑换页面内容为准，特权礼包需每月自行领取，逾期不可补领上月权益。
    
四、退订方式：
1、退订渠道：中国联通APP、联通营业厅、10010客服热线。
2、退订规则：退订、变更次月生效。`;

      const text2 = `尊敬的客户：
您好!
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。 为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息: 
(1)事先获得客户的明确授权；
(2)根据有关的法律法规要求；
(3)按照相关司法机关和/或政府主管部门的要求；
(4)为维护社会公众的利益所必需且适当；
(5)为维护我公司或客户的合法权益所必需且适当。
      `;

      this.$dialog.alert({
        messageAlign: "left",
        title: title,
        message: title == "《活动规则》" ? text1 : text2,
      });
    },
    close() {
      this.visibility = false;
      this.$toast.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #da1240;
  // padding-bottom: 160px;

  .img {
    width: 100%;
  }

  .content {
    ::v-deep .van-form {
      background: transparent;
      position: relative;
      padding: 60px 0 0px;

      .step1,
      .step2,
      .step3 {
        width: 300px;
        position: absolute;
        z-index: 1;
        pointer-events: none;
      }

      .step1 {
        transform: translate(90px, -70px);
      }

      .step2 {
        width: 120px;
        right: 70px;
        top: 156px;
      }

      .step3 {
        width: 310px;
        transform: translate(90px, -8px);
      }

      .van-cell {
        width: 630px;
        height: 90px;
        background-color: #ffffff;
        border-radius: 80px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 0 0 40px;

        &.input-code {
          margin: 44px auto;

          .code {
            width: 212px;
            height: 100px;
            text-align: center;
            line-height: 36px;
            font-size: 30px;
            font-weight: 500;
            color: #fff;
            background-color: #ff840d;
            border-radius: 0 20px 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-left: 5px solid #e13449;
          }
        }

        .van-field__body {
          height: 100%;
        }

        &::after {
          border: none;
        }

        .van-field__control {
          font-size: 30px;
          // color: #757575;

          &::-webkit-input-placeholder {
            font-size: 30px;
            color: #757575;
            font-weight: 500;
          }
        }
      }

      .submit {
        width: 630px;
        display: block;
        margin: 0px auto 60px;
        // animation: identifier 1.5s infinite;

        // @keyframes identifier {
        //   0% {
        //     transform: scale(1);
        //   }
        //   25% {
        //     transform: scale(0.9);
        //   }
        //   50% {
        //     transform: scale(1);
        //   }
        //   75% {
        //     transform: scale(0.9);
        //   }
        // }
      }

      .checked {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 26px;
        font-weight: 400;
        padding: 10px 40px 0;
        transform: translateY(-60px);

        .van-checkbox {
          width: auto;

          &__label {
            font-weight: 400;

            margin-left: 4px;
            color: #fff !important;

            .book {
              color: #ffe400;
            }
          }

          &__icon--checked .van-icon {
            color: #e54853;
            background-color: #ffce29 !important;
          }
        }
      }
    }

    .img {
      padding: 0 18px 10px;
      box-sizing: border-box;
    }

    .info {
      color: #ffffff;
      padding: 0 36px;

      .title {
        text-align: center;
        font-size: 36px;
        padding: 40px 0;
      }

      .text {
        font-size: 28px;
        font-weight: 500;
        // line-height: 38px;
        line-height: 1.6;
      }
    }
  }

  ::v-deep .dialog-confirm {
    .footer {
      padding-top: 590px;
      align-items: center;

      .btn-close {
        width: 200px;
        padding: 0 30px 0 34px;
      }

      .btn-submit {
        width: 228px;
      }
    }
  }
}
</style>